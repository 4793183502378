import React from "react";
import AdminContent from "../../componets/Admin/AdminContent/AdminContent";
import "./AdminPage.scss";
function AdminPage() {
  return (
    <div className="Adminpage">
      <AdminContent />
    </div>
  );
}

export default AdminPage;
